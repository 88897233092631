.buttonGroup .btn1,
.buttonGroup .btn2,
.buttonGroup .btn3 {
  align-items: center;
  background-image: url(../images/btnbg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-flex;
  font-size: 15px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 91px;
  min-width: 180px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  background-position: center;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.buttonGroup .btn1:hover,
.buttonGroup .btn2:hover,
.buttonGroup .btn3:hover {
  color: #00FFFF !important;
}

header {
  position: relative;
  filter: drop-shadow(0px -60px 60px #000b2b);
  top: 0px;
  z-index: 4;
  transition: all 0.3s ease-in-out;
}
header.openmenu {
  position: sticky;
}
header .container {
  max-width: 1100px;
}
header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  background-image: url(../../Assets/images/header-frame.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.headersec {
  display: flex;
  justify-content: space-between;
}

.logo img {
  width: 100%;
  max-width: 70px;
  padding: 15px 15px 15px 0px;
}

.logoDesktop {
  top: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.logoDesktop img {
  width: 100%;
  max-width: 120px;
  padding: 5px;
  transform: scale(1.5);
}
.logoDesktop h2 {
  font-family: "defiworld-Bold1";
  font-size: 42px;
  color: #3df9ff;
}
.logoDesktop ul {
  display: flex;
  font-family: "defiworld-Bold1";
  margin: 0px;
  padding: 0px;
  justify-content: center;
  text-shadow: 2px 2px 50px #50DBFF;
}
.logoDesktop ul li {
  list-style: none;
  letter-spacing: 6px;
  font-size: 32px;
  animation: ani 1.2s linear infinite;
}
.logoDesktop ul li:nth-child(1) {
  animation-delay: 0.2s;
}
.logoDesktop ul li:nth-child(2) {
  animation-delay: 0.6s;
}
.logoDesktop ul li:nth-child(3) {
  animation-delay: 0.8s;
}
.logoDesktop ul li:nth-child(4) {
  animation-delay: 1s;
}
.logoDesktop ul li:nth-child(5) {
  animation-delay: 1.4s;
}
.logoDesktop ul li:nth-child(6) {
  animation-delay: 1.8s;
}
.logoDesktop ul li:nth-child(7) {
  animation-delay: 1.12s;
}
.logoDesktop ul li:nth-child(8) {
  animation-delay: 1.16s;
}
.logoDesktop ul li:nth-child(9) {
  animation-delay: 1.2s;
}
@keyframes ani {
  0% {
    text-shadow: 2px 2px 10px #50dbff;
  }
  90% {
    text-shadow: 2px 2px 20px #50dbff;
  }
  100% {
    text-shadow: 0 0 7px #3df9ff, 0 0 20px #3df9ff;
  }
}

.logoDesktop2 {
  top: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.logoDesktop2 img {
  width: 100%;
  max-width: 120px;
  padding: 5px;
  transform: scale(1.5);
}
.logoDesktop2 h2 {
  font-family: "defiworld-Bold1";
  font-size: 42px;
  color: #3df9ff;
}
.logoDesktop2 ul {
  display: flex;
  font-family: "defiworld-Bold1";
  margin: 0px;
  padding: 0px;
  justify-content: center;
  color: #fff;
}
.logoDesktop2 ul li {
  list-style: none;
  letter-spacing: 6px;
  font-size: 32px;
  animation: ani 1.2s linear infinite;
}
.logoDesktop2 ul li:nth-child(1) {
  animation-delay: 0.2s;
}
.logoDesktop2 ul li:nth-child(2) {
  animation-delay: 0.6s;
}
.logoDesktop2 ul li:nth-child(3) {
  animation-delay: 0.8s;
}
.logoDesktop2 ul li:nth-child(4) {
  animation-delay: 1s;
}
.logoDesktop2 ul li:nth-child(5) {
  animation-delay: 1.4s;
}
.logoDesktop2 ul li:nth-child(6) {
  animation-delay: 1.8s;
}
.logoDesktop2 ul li:nth-child(7) {
  animation-delay: 1.12s;
}
.logoDesktop2 ul li:nth-child(8) {
  animation-delay: 1.16s;
}
.logoDesktop2 ul li:nth-child(9) {
  animation-delay: 1.2s;
}
@keyframes ani {
  0% {
    text-shadow: 2px 2px 10px #50dbff;
  }
  90% {
    text-shadow: 2px 2px 20px #50dbff;
  }
  100% {
    text-shadow: 0 0 7px #3df9ff, 0 0 20px #3df9ff;
  }
}

.menu {
  position: relative;
  top: 0px;
}
.menu li {
  display: inline-block;
  margin: 0px 12px;
}
.menu a {
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-weight: 400;
  cursor: pointer;
}

.balancebox {
  display: flex;
  align-items: center;
}
.balancebox li {
  display: inline-block;
}
.balancebox li:nth-last-child(1) {
  margin-left: 10px;
}
.balancebox li span {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #50dbff;
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
  min-width: auto;
  margin: 0px;
}
.balancebox li span::before {
  width: 100px !important;
}
.balancebox li span::after {
  width: 100px !important;
}
.balancebox li strong {
  margin-left: 8px;
}

.rightboxmenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  bottom: 0px;
}

.hamburger {
  cursor: pointer;
  width: 35px !important;
  height: 26px;
  position: relative;
  margin-left: 15px;
  z-index: 1;
}
.hamburger span {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  background-color: #02ffff;
  display: inline-block;
  content: "";
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
}
.hamburger span:before {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 100%;
  background-color: #50dbff;
  position: absolute;
  display: inline-block;
  content: "";
  top: -10px;
  left: 0px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 100%;
  background-color: #50dbff;
  position: absolute;
  display: inline-block;
  content: "";
  bottom: -10px;
  left: 0px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger.is-active span {
  background-color: transparent;
}
.hamburger.is-active span:before {
  top: 0;
  transform: rotate(45deg);
}
.hamburger.is-active span:after {
  top: 0;
  transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

.menubar {
  position: fixed;
  top: 0;
  left: -360px;
  width: 0px;
  height: 100%;
  z-index: 3;
  background-color: #041516;
  display: block !important;
  box-shadow: -20px 0px 30px 0px #000000;
  transition: all 0.3s ease-in-out;
  padding-top: 140px;
  padding-left: 20px;
}
.menubar .menu {
  display: block;
  padding-top: 30px !important;
}
.menubar .menu li {
  display: block;
  margin-bottom: 10px;
}
.menubar .menu li a {
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 20px;
}

.mobileMenu {
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1840px) {
  header .container {
    max-width: 950px;
  }
  .menu li {
    margin: 0px 10px;
  }
}
@media only screen and (max-width: 1440px) {
  .menu li {
    margin: 0px 10px;
  }
  .menu a {
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 500;
  }
  header .container {
    max-width: 950px;
  }
  header .clipHeader {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 0 100%, 0 0);
  }
  header .clipHeader::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 10px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 15px) calc(100% - 20px), calc(100% - 170px) calc(100% - 20px), calc(100% - 190px) calc(100% - 40px), 180px calc(100% - 40px), 150px 100%, 10px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
  }
  header .before {
    width: 300px;
  }
}
@media only screen and (max-width: 992px) {
  .balancebox li span {
    padding: 10px 15px;
    letter-spacing: 1px;
    margin: 0px;
  }
}
@media only screen and (max-width: 767px) {
  header .clipHeader {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0 100%, 0 0);
  }
  header .clipHeader::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), calc(100% - 5px) calc(100% - 40px), calc(100% - 0px) calc(100% - 40px), calc(100% - 0px) calc(100% - 0px), calc(100% - 0px) calc(100% - 40px), 180px calc(100% - 40px), 140px 100%, 0px 100%, 2px calc(100% - 10px), 0px calc(100% - 10px), 0px 100%, 0 100%, 0 0);
  }
  .rightboxmenu {
    bottom: 0px;
  }
  header::before {
    height: 120px;
  }
  .logoDesktop {
    top: 60px;
  }
  .logoDesktop ul li {
    font-size: 22px;
  }
  .logoDesktop2 {
    top: 85px;
  }
  .logoDesktop2 ul li {
    font-size: 22px;
  }
  .buttonGroup .btn1,
  .buttonGroup .btn2,
  .buttonGroup .btn3 {
    min-height: 74px;
    min-width: 156px;
  }
}
@media only screen and (max-width: 992px) {
  .menu li {
    margin: 0px 9px;
  }
}
@media only screen and (max-width: 767px) {
  .logo img {
    max-width: 70px;
  }
}
@media only screen and (max-width: 390px) {
  .balancebox {
    font-size: 14px;
  }
  .balancebox li span {
    padding: 8px 10px;
  }
  .hamburger {
    width: 30px !important;
    height: 23px;
    margin-left: 10px;
  }
}/*# sourceMappingURL=header.css.map */