.boxsmain {
  margin-top: 50px;
}
.boxsmain .os_container2 {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.boxsmain .os_row {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.boxsmain .cardbox {
  background: linear-gradient(rgb(13, 50, 82) 0%, rgba(17, 48, 73, 0) 70.45%);
  padding: 20px 15px;
  border-radius: 7px;
  position: relative;
  text-align: center;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
  height: auto;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.boxsmain .cardbox h2 {
  margin: 0 0 8px;
}
.boxsmain .cardbox p {
  margin: 0px;
  font-size: 16px;
}
.boxsmain .cardbox::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -9px;
  background-color: #04060d;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-left: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.boxsmain .cardbox::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: -9px;
  background-color: #04060d;
  transform: rotate(-45deg);
  width: 25px;
  height: 35px;
  border-right: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.boxsmain .titleh2 {
  text-align: center;
}
.boxsmain .titleh2 h2 {
  text-align: center;
  margin: 0 0 15px;
  font-size: 35px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
  color: #ffffff;
  margin-top: 20px;
}

body .MuiPopover-paper-MuiMenu-paper {
  background-color: #212529 !important;
  color: #fff !important;
}

.boxsmain {
  margin-top: 50px;
}
.boxsmain .os_container {
  max-width: 800px;
  margin: 0 auto;
}

body .adminReportTable .os_container {
  max-width: 800px;
}
body .adminReportTable .react-tabs .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
body .adminReportTable .react-tabs .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  padding: 15px;
  text-align: center;
}
body .adminReportTable .table-responsive {
  overflow: auto;
}
body .adminReportTable .table-responsive .text-right {
  text-align: right;
}
body .adminReportTable .table-responsive .thead .tr {
  background-color: #680D48;
}
body .adminReportTable .table-responsive .thead .tr .th {
  padding: 15px;
}
body .adminReportTable .table-responsive .tbody .tr .td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
  width: 100%;
}
body .adminReportTable .table-responsive .tr .td {
  padding: 10px;
}
body .adminReportTable .table-responsive .table {
  width: 100%;
}

body .MuiBox-root {
  max-width: 950px !important;
  margin: 0 auto !important;
  position: relative !important;
}

body .MuiTabs-flexContainer {
  justify-content: center !important;
}

body .MuiBox-root {
  background-color: transparent !important;
}

body .MuiTabs-indicator {
  display: none;
}

body .MuiTableContainer-root {
  background-color: transparent !important;
  border: 1px solid hsl(199.38, 71.03%, 56.51%) !important;
  color: #fff !important;
  margin: 0 auto !important;
  max-width: 1500px !important;
}

body .MuiTableCell-root {
  color: #fff !important;
  text-align: center !important;
  border-bottom: 1px solid #41acdf !important;
  border-left: 1px solid #41acdf !important;
  padding: 12px 12px 17px 12px !important;
  min-width: 72px !important;
}

.btnexcel {
  margin-top: 15px !important;
  margin-left: auto !important;
  display: block !important;
}

body .MuiPickersLayout-root {
  display: grid;
  grid-auto-columns: max-content auto max-content;
  grid-auto-rows: max-content auto max-content;
  overflow: hidden;
  min-width: 320px;
  background-color: #121212;
  /* overflow: scroll; */
  margin: 20px;
  border: 1px solid #fff;
}

body .MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body .MuiStack-root.MuiMultiInputDateRangeField-root {
  width: 500px !important;
  margin: 0 auto;
}

body .MuiFormControl-root-MuiTextField-root {
  border: 1px solid !important;
}

body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth {
  max-width: 500px !important;
}

body .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
  border: 1px solid #426a9b !important;
}

body .MuiFormControl-root.MuiTextField-root {
  margin: 0 auto;
  margin-bottom: 20px;
}

body .MuiInputBase-root.MuiOutlinedInput-root {
  border: 1px solid hsl(199.38, 71.03%, 56.51%) !important;
}

body .MuiPickersLayout-contentWrapper .MuiDateRangeCalendar-root > div:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}

body .MuiOutlinedInput-input {
  color: #fff !important;
}

@media only screen and (max-width: 1878px) {
  body .MuiList-root {
    display: flex !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
    justify-content: center !important;
    margin: 0 auto !important;
  }
}
@media only screen and (max-width: 768px) {
  body .MuiPickersToolbar-content {
    display: flex !important;
    justify-content: center !important;
  }
  body .MuiPickersToolbar-root {
    display: flex !important;
    align-items: center !important;
  }
  body .MuiPickersLayout-root {
    display: block !important;
  }
  body .MuiPickersLayout-shortcuts {
    display: flex !important;
  }
  body .MuiList-root {
    max-width: 1 !important;
  }
  body .MuiDateRangeCalendar-root {
    margin: 0 auto !important;
  }
}/*# sourceMappingURL=Adminpanel.css.map */