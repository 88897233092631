@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Catamaran&family=DM+Serif+Display&family=Expletus+Sans&family=Josefin+Sans:ital@1&family=Karla&family=Major+Mono+Display&family=Montserrat&family=Open+Sans&family=Roboto+Mono:wght@300&family=Ubuntu:wght@500;700&display=swap");
@font-face {
  font-family: "Power Grotesk UltBd";
  src: url("../font/PowerGrotesk-UltraBold.eot");
  src: url("../font/PowerGrotesk-UltraBold.eot") format("embedded-opentype"), url("../font/PowerGrotesk-UltraBold.woff2") format("woff2"), url("../font/PowerGrotesk-UltraBold.woff") format("woff"), url("../font//PowerGrotesk-UltraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mabry Pro";
  src: url("../font/MabryPro-Bold.eot");
  src: url("../font/MabryPro-Bold.eot") format("embedded-opentype"), url("../font//MabryPro-Bold.woff2") format("woff2"), url("../font/MabryPro-Bold.woff") format("woff"), url("../font//MabryPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "defiworld-Regular";
  src: url("../font/defiworld-Regular.eot");
  src: url("../font/defiworld-Regular.eot?#iefix") format("embedded-opentype"), url("../font/defiworld-Regular.woff2") format("woff2"), url("../font/defiworld-Regular.woff") format("woff"), url("../font/defiworld-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "defiworld-Bold";
  src: url("../font/defiworld-Bold.eot");
  src: url("../font/defiworld-Bold.eot?#iefix") format("embedded-opentype"), url("../font/defiworld-Bold.woff2") format("woff2"), url("../font/defiworld-Bold.woff") format("woff"), url("../font/defiworld-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "defiworld-Bold1";
  src: url("../font/defiworld-Bold1.eot");
  src: url("../font/defiworld-Bold1.eot?#iefix") format("embedded-opentype"), url("../font/defiworld-Bold1.woff2") format("woff2"), url("../font/defiworld-Bold1.woff") format("woff"), url("../font/defiworld-Bold1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  font-family: "defiworld-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: white;
  text-align: left;
  background-color: #000000;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -2;
  opacity: 0;
}
body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/abcd.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -3;
  transform: scale(1.1);
  filter: hue-rotate(30deg) brightness(0.5);
  opacity: 0.35;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "defiworld-Bold";
  font-weight: 700;
  line-height: 1.1;
  text-shadow: 2px 2px 5px #50dbff;
}
body p {
  line-height: 1.1;
}
body .buttonGroup2 .btn1,
body .buttonGroup2 .btn2,
body .buttonGroup2 .btn3 {
  align-items: center;
  background-image: url(../images/btnbg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-flex;
  font-size: 15px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 91px;
  min-width: 180px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  background-position: center;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
body .buttonGroup2 .btn1:hover,
body .buttonGroup2 .btn2:hover,
body .buttonGroup2 .btn3:hover {
  color: #00FFFF !important;
}
body .btn {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  z-index: 10;
}
body .btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-sizing: border-box;
  transition: all 0.3s;
}
body .kave-btn {
  position: relative;
  border: solid 1px rgba(0, 214, 252, 0.3);
  background: none;
  color: rgb(112, 233, 255);
  font-family: "defiworld-Regular", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  width: 200px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  margin: 10px;
  box-shadow: 1px 1px 8px rgb(0, 214, 252);
  color: rgb(0, 214, 252);
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}
body .kave-btn.kave-btn:before {
  left: 0;
  width: 20px;
}
body .kave-btn.kave-btn:after {
  right: 0;
  width: 20px;
}
body .kave-btn .kave-line:before {
  bottom: 0;
}
body .kave-btn .kave-line:after {
  top: 0;
}
body .kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: rgb(0, 214, 252);
  transition: all 0.25s ease;
}
body .kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: rgb(0, 214, 252);
  transition: all 0.25s ease;
}
body img {
  max-width: 100%;
  height: auto;
  width: auto;
}
body a {
  color: #83c8ff;
  outline: none;
}
body a:hover {
  color: #ffbebe;
}
body #root {
  min-height: 100vh;
}
body main {
  padding: 40px 0px;
}
body main section {
  margin-bottom: 80px;
}
body main section:nth-last-child(1) {
  margin-bottom: 0px;
}
body .infonft {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body .banner-area {
  padding: 135px 0;
  position: relative;
}
body .mainsec {
  position: relative;
}
body .nftsec {
  position: relative;
}
body .inrInfoBx {
  text-align: center;
  margin: 0 auto;
}
body .inrInfoBx .imginrBx {
  height: 150px;
  position: relative;
  width: 160px;
  margin: 0 auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .inrInfoBx .imginrBx::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: hue-rotate(260deg);
  opacity: 0.4;
}
body .inrInfoBx p {
  font-size: 16px;
}
body .inrInfoBx strong {
  font-size: 24px;
  display: block;
}
body .inrInfoBx img {
  max-width: 100px;
  margin: 0 auto 0px;
  display: block;
  position: relative;
}
body .borderboxmain img {
  width: calc(100% - 2vw);
  height: auto;
  display: block;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
}
body .borderboxmain img.linetopleft {
  top: -1vw;
  left: 1vw;
  -o-object-position: left;
     object-position: left;
}
body .borderboxmain img.linebottomright {
  bottom: -3vw;
  right: 1vw;
  -o-object-position: right;
     object-position: right;
}
body .nftmainimg {
  position: relative;
  margin: 0 auto;
}
body .nftmainimg .nftvideo {
  width: 100%;
  height: auto;
  max-width: 500px;
  margin-top: -50px;
}
body .ntfsecdata {
  display: flex;
  gap: 25px;
}
body .osrow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}
body .row {
  display: flex !important;
  flex-wrap: wrap !important;
}
body .button-prev,
body .button-next {
  width: 50px;
  height: 50px;
  background-color: #333;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}
body .button-prev:hover,
body .button-next:hover {
  background-color: #00FFFF;
}
body .icon {
  width: 24px;
  height: 24px;
}
body .planbutton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
body .carousel-indicators {
  margin-bottom: 10px;
  position: absolute;
  bottom: auto;
  z-index: 1;
  margin-top: -50px;
}
body .carousel-indicators button::before {
  display: none;
}
body .carousel-indicators [data-bs-target] {
  text-indent: 0px;
  color: white;
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 100px;
  height: 40px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  opacity: 1;
  transition: opacity 0.6s ease;
}
body .carousel-indicators button.active {
  background-color: transparent;
  background-image: linear-gradient(128deg, #00ffff 0%, #156666 100%);
}
body .historysec {
  padding-block: 10px;
}
body .historysec nav {
  justify-content: space-around;
  align-items: center;
  padding-block: 15px;
}
body .historysec table thead tr {
  background-color: transparent;
  overflow: hidden;
  justify-content: space-between;
}
body .historysec table {
  position: relative;
  overflow: scroll;
}
body .historysec table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
body .historysec .page-link {
  background-color: transparent;
  color: #fff;
}
body .history-headline {
  font-family: "defiworld-Bold";
  font-weight: 700;
  text-shadow: 2px 2px 5px #50dbff;
  font-size: 45px;
  text-transform: capitalize;
  text-align: center;
}
body .table-container {
  overflow-x: auto;
  width: 100%;
}
body .table-container table {
  min-width: 600px;
  width: 100%;
  border-collapse: collapse;
}
body .table-container th,
body .table-container td {
  border: 1px solid #00ffff;
}
body .nftBalance .balanceViewBx {
  padding: 18px;
  height: 100%;
  background-color: rgba(63, 226, 255, 0.1490196078);
  border-radius: 10px;
  box-shadow: 0px 0px 100px -80px #ffffff;
}
body .nftBalance .balanceViewBx ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
body .nftBalance .balanceViewBx ul li {
  margin-bottom: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .nftBalance .balanceViewBx ul li:nth-last-child(1) {
  margin-bottom: 0px;
}
body .nftBalance .balanceViewBx ul li span {
  text-align: right;
}
body .nftBalance .balanceViewBx ul li svg {
  margin-left: 10px;
  width: 22px;
  height: auto;
  display: inline-block;
  cursor: pointer;
}
body .nftmainimg {
  margin: 0 auto;
  max-width: 450px;
  display: flex;
  justify-content: center;
  mix-blend-mode: lighten;
}
body .connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);
}
body .connectWalletToggle .modal-dialog {
  max-width: 450px;
}
body .connectWalletToggle .modal-content {
  color: #ffffff;
  background-color: #0a0a0a;
  box-shadow: 0px 0px 70px -40px #666666;
  padding: 0px 20px;
  border: 1px solid #4a4a4d;
}
body .connectWalletToggle .modal-content .modal-header {
  align-items: flex-start;
  border: none;
  padding: 20px 10px;
}
body .connectWalletToggle .modal-content .modal-body {
  padding: 10px;
}
body .connectWalletToggle .modal-content button.btn-close {
  outline: none;
  box-shadow: none !important;
  opacity: 1;
  filter: invert(1);
}
body .connectWalletToggle .modal-content .modal-title {
  font-size: 24px;
  margin-bottom: 0px;
  font-family: "Power Grotesk UltBd";
  color: #ffffff;
  text-shadow: 0.3px 0.3px 0.3px #111;
}
body .connectWalletToggle .modal-content .modal-title p {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin: 10px 0px 0px;
  text-shadow: none;
}
body .connectWalletToggle .popupbtngrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
body .connectWalletToggle .popupbtngrp .btngrp {
  flex-basis: calc(33.33% - 0px);
  max-width: calc(33.33% - 0px);
  margin: 0px 0px 20px;
}
body .connectWalletToggle .popupbtngrp button,
body .connectWalletToggle .popupbtngrp a {
  background-color: #000000;
  border: 1px solid #221d26;
  color: #ffffff;
  font-size: 16px;
  padding: 20px 8px 20px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 1.1;
  display: block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
body .connectWalletToggle .popupbtngrp button:hover,
body .connectWalletToggle .popupbtngrp a:hover {
  border-color: #523664;
}
body .connectWalletToggle .popupbtngrp button img,
body .connectWalletToggle .popupbtngrp a img {
  display: block;
  margin: 0 auto 15px;
  height: 50px;
  width: 50px;
  background-color: rgba(0, 0, 0, 0.169);
  -o-object-fit: contain;
     object-fit: contain;
}
body .connectWalletToggle .popupbtngrp button span,
body .connectWalletToggle .popupbtngrp a span {
  display: block;
}
body .informationMU .infotitle {
  margin-bottom: 30px;
}
body .informationMU .infotitle h2 {
  font-size: 48px;
}
body .informationMU .infoimage img {
  width: 100%;
  margin: 0 auto;
  mix-blend-mode: exclusion;
  filter: brightness(1.3);
  max-width: 420px;
}
body .informationMU .infotxt h3 {
  font-size: 42px;
  margin-bottom: 20px;
}
body .informationMU .infotxt p {
  letter-spacing: 2px;
  color: #c9c9c9;
  text-align: justify;
}
body .informationMU .infotxt p span {
  color: #ffffff;
  font-weight: 100;
}
body .Toastify__progress-bar-theme--light {
  background: #ce86f9;
}
body .Toastify__toast {
  color: #000000;
  font-size: 18px;
}
body .bgVideoBody {
  -o-object-fit: cover;
  object-fit: cover;
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.0001;
}
body .bgVideoBody,
body .bgVideoBody video {
  -o-object-fit: cover;
  object-fit: cover;
}
body .loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed to center vertically */
}
body .loader {
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}
body .loader img {
  max-width: 200px;
}
body .countDownMu {
  text-align: center;
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 28px;
  padding-top: 25px;
  flex-wrap: wrap;
}
body .countDownMu b {
  display: flex;
  text-align: center;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 20px;
  margin: 0px 10px;
  border-radius: 6px;
  position: relative;
}
body .countDownMu b span {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  font-family: "Power Grotesk UltBd";
}
body .countDownMu p {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 15px auto 0px;
  text-align: center;
  font-size: 16px;
  font-family: "Power Grotesk UltBd";
}

.bannerbtn2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-changing-element {
  mix-blend-mode: exclusion;
  width: 100%;
  height: auto;
  max-width: 500px;
  margin: 0 auto 50px;
}

.info-group p {
  line-height: 1.5 !important;
  font-size: 16px !important;
}

.titlebox {
  text-align: center;
  max-width: 500px;
  margin: 0px auto 80px;
  position: relative;
}
.titlebox::before, .titlebox::after {
  content: "";
  position: absolute;
  top: 60%;
  width: 100%;
  height: 70px;
  background-image: url(../../Assets//images//titleframe.svg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 9;
  filter: hue-rotate(40deg);
  animation: blinkers 1.5s linear infinite;
  filter: drop-shadow(0 0 2px #50DBFF) hue-rotate(40deg);
  transition: 0.9s;
}
@keyframes blinkers {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.titlebox::before {
  left: 100%;
  transform: translate(5%, -50%);
}
.titlebox::after {
  right: 100%;
  transform: translate(-5%, -50%) scaleX(-1);
}
.titlebox.titleboxNft {
  max-width: 600px;
  margin: 0px auto 10px;
}
.titlebox.titleboxNft h2 {
  margin-bottom: 10px;
}
.titlebox.titleboxNft::before, .titlebox.titleboxNft::after {
  display: none;
}
.titlebox h2 {
  font-size: 48px;
  margin-bottom: 25px;
  line-height: 1;
  text-shadow: 2px 2px 50px #50dbff;
}

@media only screen and (max-width: 767px) {
  .titlebox::before, .titlebox::after {
    display: none;
  }
}/*# sourceMappingURL=main.css.map */