@use '../../Assets/css/var';

.faqs {
    margin: 0 auto 60px;

    .faq-row-wrapper {
        position: relative;

        .faq-body {
            // border: 1px solid var.$colour-theme;
            border-radius: 0px;
        }

        .faq-title {
            text-align: center;
            justify-content: center;
            padding: 0;
            margin: 0px 0px 40px;
            border: none;

            h2 {
                font-size: 42px !important;
            }
        }

        .faq-body {
            .faq-row {
                // border: 1px solid var.$colour-theme-dark;
                margin-bottom: 5px;
                padding: 0px;
                border: none;

                .row-title {
                    padding: 10px 40px 10px 0px;
                    font-family: "defiworld-Bold";
                    letter-spacing: 1px;
                    margin-top: 15px;
                }

                .icon-wrapper {
                    top: 10px !important;
                    right: 0px !important;
                    background-color: #214e7a;
                }

                .row-content-text {
                    color: var.$colour-white !important;
                    font-size: 14px !important;
                    opacity: 0.9;
                    padding-right: 40px !important;

                    a {
                        color: #64bbff;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
.faQ {

  
        color: #50dbff !important;


    
       
    
}

@media only screen and (max-width: 768px) {
    .faqs .faq-row-wrapper {
        padding: 0px;
    }

    .faqs .faq-row-wrapper .faq-body {
        padding: 0px;
    }

    .faqs .faq-row-wrapper .faq-body .faq-row .row-title {
        margin-top: 0px;
    }

    .faqs .faq-row-wrapper::before,
    .faqs .faq-row-wrapper::after {
        display: none;
    }

    .faqs .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY {
        font-size: 16px;
    }

    .faqs .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
        padding-right: 0px;
    }
}