// admin report //

.boxsmain {
    margin-top: 80px;
    margin-bottom: 40px;
    .os_container2 {
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 50px;
    }
    .os_row {
      justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      
    }
  
    .cardbox {
      background: linear-gradient(rgb(13, 50, 82) 0%, rgba(17, 48, 73, 0) 70.45%);
      padding: 20px 15px;
      border-radius: 7px;
      position: relative;
      text-align: center;
      border: 1px solid hsl(199.38, 71.03%, 56.51%);
      height: auto;
      width: 100%;
      margin: 0 auto;
      h2 {
        margin: 0 0 8px;
        // font-size: 20px;
      }
      p {
        margin: 0px;
        font-size: 16px;
        margin-bottom: 7px;
      }
      &::after {
        content: "";
        position: absolute;
        top: -14px;
        right: -9px;
        background-color: #04060d;
        transform: rotate(-45deg);
        width: 25px;
        height: 35px;
        border-left: 1px solid hsl(199.38, 71.03%, 56.51%);
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -14px;
        left: -9px;
        background-color: #04060d;
        transform: rotate(-45deg);
        width: 25px;
        height: 35px;
        border-right: 1px solid hsl(199.38, 71.03%, 56.51%);
      }
    }
    .titleh2 {
      text-align: center;
      h2 {
        text-align: center;
        margin: 0 0 15px;
        font-size: 35px;
        font-weight: 500;
        text-transform: capitalize;
        padding: 0;
        margin-bottom: 20px;
        color: #ffffff;
        margin-top: 20px;
      }
    }
  
  }
  body .MuiPopover-paper-MuiMenu-paper {
    background-color: #212529 !important;
    color: #fff !important;
}
  // admin report //
// .boxsmain {
//     margin-top: 50px;
//     .os_container {
//       max-width: 800px;
//       margin: 0 auto;
//     }
   
   
//   }
 
  // table1 //

  body .adminReportTable {
    .os_container {
      max-width: 800px;
    }
    .react-tabs {
      .react-tabs__tab-list {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .react-tabs__tab {
          width: 100%;
          padding: 15px;
          text-align: center;
        }
      }
    }
    .table-responsive {
      overflow: auto;
      .text-right {
        text-align: right;
      }
      .thead {
        .tr {
          background-color: #680D48;
          .th {
            padding: 15px;
          }
        }
      }
      .tbody {
        .tr {
          .td {
            border-bottom: 1px solid #323232;
            padding: 15px 0px;
            margin: 0;
            width: 100%;
          }
        }
      }
      .tr {
        .td {
          padding: 10px;
        }
      }
      .table {
        width: 100%;
      }
    }
  }
   body .MuiBox-root {
    max-width: 950px !important;
    margin: 0 auto !important;
    position: relative !important;
   }
body .MuiTabs-flexContainer {
  justify-content: center !important;
}

body .MuiBox-root  {
  background-color: transparent !important;
}
body .MuiTabs-indicator {
display: none;
}
body  .MuiTableContainer-root {
  background-color: transparent !important;
  border: 1px solid hsl(199.38, 71.03%, 56.51%) !important;
  color: #fff !important;
  margin: 0 auto !important;
  max-width: 1500px !important;
  cursor: grab;
}
.MuiTableContainer-root::-webkit-scrollbar {
  width: 2px;
  height: 3px;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #41acdf;
}

 body .MuiTableCell-root {
  color: #fff !important;
  text-align: center !important;
  border-bottom: 1px solid #41acdf !important;
  border-left: 1px solid #41acdf !important; 
  padding: 11px !important;
  min-width: 72px !important;
  white-space: nowrap !important;

}
body .MuiPickersLayout-shortcuts {
 width: calc(100% - 20px) !important;
}

.btnexcel{
  margin-top: 15px !important ;
  margin-left: auto !important;
  display: block !important;
}

body .MuiDateRangeCalendar-root {
justify-content: center !important;
}
body  .MuiPickersLayout-toolbar {
margin:  0 auto !important;
}
body .MuiPickersLayout-root {
  display: grid;
  grid-auto-columns: max-content auto max-content;
  grid-auto-rows: max-content auto max-content;
  overflow: hidden;
  min-width: 258px;
  background-color: #121212;
  margin: 20px 0px;
  margin-bottom: 50px;
  border: 1px solid #426a9b;
  border-radius: 9px;
}
body .MuiDayCalendar-slideTransition {
  min-width: 280px !important;
}
// datepicker //

body .MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
body .MuiStack-root.MuiMultiInputDateRangeField-root {
  width: 500px !important;
  margin: 0 auto;
}
body .MuiFormControl-root-MuiTextField-root {
  border: 1px solid !important;
}
body .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth {
  max-width: 500px !important;
}
body .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
  border: 1px solid #426a9b !important;
}

body .MuiFormControl-root.MuiTextField-root {
  margin: 0 auto;
  margin-bottom: 20px;
}
body .MuiInputBase-root.MuiOutlinedInput-root {
  border: 1px solid hsl(199.38, 71.03%, 56.51%) !important;
}

body
  .MuiPickersLayout-contentWrapper
  .MuiDateRangeCalendar-root
  > div:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}
body .MuiOutlinedInput-input {
  // text-align: center !important;
  color: #fff !important;
}
// extable //

// extable //

.extable{
  margin-top: 100px;
  margin: 0px auto;
  max-width: 1300px;
  h1{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  }


@media only screen and (max-width: 1878px) {
  body .MuiList-root {
    display: flex !important;
    max-width: fit-content !important;
    justify-content: center !important;
    margin: 0 auto !important;
  
  }
}

 



@media only screen and (max-width: 768px) {
  .boxsmain {
    margin-top: 40px;
  }
  .btnexcel {
    margin-right: 20px  !important;
}
  body  .MuiTableContainer-root {
    width: calc(100% - 38px) !important;
  }
  body .MuiPickersLayout-root {
    margin: 20px !important;
  }
  body .MuiPickersToolbar-content {
display: flex !important;
justify-content: center !important;
}
body .MuiPickersToolbar-root{
display: flex !important;
align-items: center !important;
}
  body .MuiPickersLayout-root {
    display: block !important;
  }
  body .MuiPickersLayout-shortcuts {
    display: flex !important;
}
body .MuiList-root {
  max-width: 1 !important; 
}
body .MuiDateRangeCalendar-root {
  margin: 0 auto !important;
}
}