@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Catamaran&family=DM+Serif+Display&family=Expletus+Sans&family=Josefin+Sans:ital@1&family=Karla&family=Major+Mono+Display&family=Montserrat&family=Open+Sans&family=Roboto+Mono:wght@300&family=Ubuntu:wght@500;700&display=swap");


@font-face {
  font-family: "Power Grotesk UltBd";
  src: url("../font/PowerGrotesk-UltraBold.eot");
  src: url("../font/PowerGrotesk-UltraBold.eot") format("embedded-opentype"),
    url("../font/PowerGrotesk-UltraBold.woff2") format("woff2"),
    url("../font/PowerGrotesk-UltraBold.woff") format("woff"),
    url("../font//PowerGrotesk-UltraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mabry Pro";
  src: url("../font/MabryPro-Bold.eot");
  src: url("../font/MabryPro-Bold.eot") format("embedded-opentype"),
    url("../font//MabryPro-Bold.woff2") format("woff2"),
    url("../font/MabryPro-Bold.woff") format("woff"),
    url("../font//MabryPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'defiworld-Regular';
  src: url('../font/defiworld-Regular.eot');
  src: url('../font/defiworld-Regular.eot?#iefix') format('embedded-opentype'),
    url('../font/defiworld-Regular.woff2') format('woff2'),
    url('../font/defiworld-Regular.woff') format('woff'),
    url('../font/defiworld-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "defiworld-Bold";
  src: url('../font/defiworld-Bold.eot');
  src: url('../font/defiworld-Bold.eot?#iefix') format('embedded-opentype'),
    url('../font/defiworld-Bold.woff2') format('woff2'),
    url('../font/defiworld-Bold.woff') format('woff'),
    url('../font/defiworld-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "defiworld-Bold1";
  src: url('../font/defiworld-Bold1.eot');
  src: url('../font/defiworld-Bold1.eot?#iefix') format('embedded-opentype'),
    url('../font/defiworld-Bold1.woff2') format('woff2'),
    url('../font/defiworld-Bold1.woff') format('woff'),
    url('../font/defiworld-Bold1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'defiworld-Regular';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: white;
  
  text-align: left;
  background-color: #000000;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-image: url(../images/bgglass.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: -2;
      opacity: 0;
  }
  &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(../images/abcd.gif);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: -3;
      transform: scale(1.1);
      filter: hue-rotate(30deg) brightness(0.5);
      opacity: 0.35;
  }









  h1,
  h2,
  h3,
  h4,
  h5,
  h6
   {

    font-family: "defiworld-Bold";
    font-weight: 700;
    line-height: 1.1;
    text-shadow: 2px 2px 5px #50dbff;
  }
  p{
    line-height: 1.1;
  }
  .buttonGroup2 {

    .btn1,
    .btn2,
    .btn3 {
      align-items: center;
      background-image: url(../images/btnbg.svg);
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;
      display: inline-flex;
      font-size: 15px;
      font-weight: 800;
      justify-content: center;
      letter-spacing: 0.8px;
      min-height: 91px;
      min-width: 180px;
      overflow: hidden;
      padding: 10px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      z-index: 1;
      background-position: center;
      text-decoration: none;
      background-color: transparent;
      box-shadow: none;
      border: none;
  
      &:hover {
        color: #00FFFF !important;
      }
    }
  }
  .btn {
    user-select: none;
    z-index: 10;
  }

  .btn:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-sizing: border-box;
    transition: all 0.3s;
  }

  // .btn5 {
  //   color: aqua;
  //   border: none;
  //   background-color: transparent;
  //   background-image: url("https://i.imgur.com/OmPxcHQ.png");
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   background-size: 100% 100%;
  //   mask-image: url("https://i.imgur.com/eJMyJYl.png");
  //   mask-type: alpha;
  //   mask-repeat: no-repeat;
  //   mask-position: center center;
  //   mask-size: 90% 80%;
  //   font-family: "defiworld-Regular";
  //   font-size: 14px;
  //   font-weight: bold;
  //   text-transform: uppercase;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   // letter-spacing: 1px;
  //   border-radius: 0;
  //   outline: none;
  //   cursor: pointer;
  //   position: relative;
  //   transition: all 0.3s;
  //   // overflow: hidden;
  //   width: 165px;
  //   height: 70px;
  // }

  // .btn5:after {
  //   background-image: url("https://i.imgur.com/jxvEiwk.png");
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   background-size: 90% 80%;
  //   width: 0%;
  //   height: 100%;
  //   top: 50%;
  //   left: 50%;
  //   opacity: 0;
  //   transform: translateX(-50%) translateY(-50%);
  // }

  // .btn5:hover,
  // .btn5:active {
  //   color: white;
  // }

  // .btn5:hover:after {
  //   width: 100%;
  //   opacity: 1;
  // }


  .kave {
    &-btn {
      position: relative;
      border: solid 1px rgba(0, 214, 252, 0.3);
      background: none;
      color: rgb(112 233 255);
      font-family: "defiworld-Regular", sans-serif;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1px;
      width: 200px;
      height: 50px;
      padding: 0;
      transition: all 0.25s ease;
      outline: none;
      margin: 10px;

      box-shadow: 1px 1px 8px rgb(0 214 252);
      color: rgba(0, 214, 252, 1);
      text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);

      &.kave-btn:before {
        left: 0;
        width: 20px;
      }

      &.kave-btn:after {
        right: 0;
        width: 20px;
      }

      .kave-line:before {
        bottom: 0;
      }

      .kave-line:after {
        top: 0;
      }
    

      // &:hover {
      //   box-shadow: 1px 1px 8px rgb(0 214 252);
      //   color: rgba(0, 214, 252, 1);
      //   text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);

      //   &.kave-btn:before {
      //     left: 0;
      //     width: 20px;
      //   }

      //   &.kave-btn:after {
      //     right: 0;
      //     width: 20px;
      //   }

      //   .kave-line:before {
      //     bottom: 0;
      //   }

      //   .kave-line:after {
      //     top: 0;
      //   }
      // }
    }

    &-line {
      &:before {
        content: "";
        position: absolute;
        bottom: 30%;
        right: 0;
        width: 1px;
        height: 20px;
        transform: translateX(1px);
        background: rgba(0, 214, 252, 1);
        transition: all 0.25s ease;
      }

      &:after {
        content: "";
        position: absolute;
        top: 30%;
        left: 0;
        width: 1px;
        height: 20px;
        transform: translateX(-1px);
        background: rgba(0, 214, 252, 1);
        transition: all 0.25s ease;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    width: auto;
  }

  a {
    color: #83c8ff;
    outline: none;

    &:hover {
      color: #ffbebe;
    }
  }

  #root {
    min-height: 100vh;
  }

  main {
    padding: 40px 0px;

    section {
      margin-bottom: 80px;

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
  }



  .infonft {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  // fontanimation ///

  .banner-area {
    padding: 135px 0;
    position: relative;

  }
  

  .mainsec {
    position: relative;
  }

  .nftsec {
    position: relative;
  }

  .inrInfoBx {
    text-align: center;
    margin: 0 auto;

    .imginrBx {
      height: 150px;
      position: relative;
      width: 160px;

      margin: 0 auto 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        filter: hue-rotate(260deg);
        opacity: 0.4;
      }


    }

    p {
      font-size: 16px;
    }

    strong {
      font-size: 24px;
      display: block;
    }

    img {
      max-width: 100px;
      margin: 0 auto 0px;
      display: block;
      position: relative;
    }
  }

  .borderboxmain {
    img {
      width: calc(100% - 2vw);
      height: auto;
      display: block;
      position: absolute;
      object-fit: contain;

      &.linetopleft {
        top: -1vw;
        left: 1vw;
        object-position: left;
      }

      &.linebottomright {
        bottom: -3vw;
        right: 1vw;
        object-position: right;
      }
    }
  }

  .nftmainimg {
    position: relative;
    margin: 0 auto;

    .nftvideo {
      width: 100%;
      height: auto;
      max-width: 500px;
      margin-top: -50px;
    }
  }

  .ntfsecdata {
    display: flex;
    gap: 25px;

    
  }
  .osrow{
    display: flex;
    flex-wrap: wrap;
   
    justify-content: center;
    gap: 40px;
  }
  .row{
    display: flex !important;
    flex-wrap: wrap !important;
  }
 



  .button-prev,
  .button-next {
    width: 50px;
    height: 50px;
    background-color: #333;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }

  .button-prev:hover,
  .button-next:hover {
    background-color: #00FFFF;
  }

  .icon {
    width: 24px;
    height: 24px;

  }

  .planbutton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  // toggle bar  //


  .carousel-indicators {
    margin-bottom: 10px;
    position: absolute;
    bottom: auto;
    z-index: 1;
    margin-top: -50px;
  }

  .carousel-indicators {
    button::before {
      display: none;
    }
  }

  .carousel-indicators [data-bs-target] {
    text-indent: 0px;
    color: white;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 100px;
    height: 40px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;

    background-clip: padding-box;
    border: 0;
    opacity: 1;
    transition: opacity .6s ease;
  }

  .carousel-indicators button.active {
    background-color: transparent;
    background-image: linear-gradient(128deg, #00ffff 0%, #156666 100%);
  }

  .historysec {
    padding-block: 10px;

    nav {
      justify-content: space-around;
      align-items: center;
      padding-block: 15px;
    }

    table thead tr {
      background-color: transparent;
      overflow: hidden;
      justify-content: space-between;
    }

    table {
      position: relative;
      overflow: scroll;
    }

    table td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .page-link {
      background-color: transparent;
      color: #fff;
    }
  }

  .history-headline {
    font-family: "defiworld-Bold";
    font-weight: 700;
 
    text-shadow: 2px 2px 5px #50dbff;
    font-size: 45px;
    text-transform: capitalize;
    text-align: center;
    // margin-bottom: 40px;

    // span {
    //   -webkit-text-stroke-width: 1px;
    //   -webkit-text-stroke-color: #fff;
    //   -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
    // }
  }

  .table-container {
    overflow-x: auto;
    width: 100%;

    table {
      min-width: 600px;
      width: 100%;
      border-collapse: collapse;
    }
  }

  .table-container th,
  .table-container td {
    border: 1px solid #00ffff;

  }


  .nftBalance {
    .balanceViewBx {
      padding: 18px;
      height: 100%;
      background-color: #3fe2ff26;
      border-radius: 10px;
      box-shadow: 0px 0px 100px -80px #ffffff;

      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;

        li {
          margin-bottom: 30px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:nth-last-child(1) {
            margin-bottom: 0px;
          }

          span {
            text-align: right;
          }

          svg {
            margin-left: 10px;
            width: 22px;
            height: auto;
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }
  }
 .nftmainimg {
    margin: 0 auto;
    max-width: 450px;
    display: flex;
    justify-content: center;
    mix-blend-mode: lighten;
}
  .connectWalletToggle {
    background-color: rgba(0, 0, 0, 0.8);

    .modal-dialog {
      max-width: 450px;
    }

    .modal-content {
      color: #ffffff;
      background-color: #0a0a0a;
      box-shadow: 0px 0px 70px -40px #666666;
      padding: 0px 20px;
      border: 1px solid #4a4a4d;

      .modal-header {
        align-items: flex-start;
        border: none;
        padding: 20px 10px;
      }

      .modal-body {
        padding: 10px;
      }

      button.btn-close {
        outline: none;
        box-shadow: none !important;
        opacity: 1;
        filter: invert(1);
      }

      .modal-title {
        font-size: 24px;
        margin-bottom: 0px;
        font-family: "Power Grotesk UltBd";
        color: #ffffff;
        text-shadow: 0.3px 0.3px 0.3px #111;

        p {
          font-size: 14px;
          line-height: 1.2;
          font-weight: 400;
          margin: 10px 0px 0px;
          text-shadow: none;
        }
      }
    }

    .popupbtngrp {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .btngrp {
        flex-basis: calc(33.33% - 0px);
        max-width: calc(33.33% - 0px);
        margin: 0px 0px 20px;
      }

      button,
      a {
        background-color: #000000;
        border: 1px solid #221d26;
        color: #ffffff;
        font-size: 16px;
        padding: 20px 8px 20px;
        border-radius: 5px;
        letter-spacing: 1px;
        line-height: 1.1;
        display: block;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        text-align: center;
        width: 100%;

        &:hover {
          border-color: #523664;
        }

        img {
          display: block;
          margin: 0 auto 15px;
          height: 50px;
          width: 50px;
          background-color: rgba(0, 0, 0, 0.169);
          object-fit: contain;
        }

        span {
          display: block;
        }
      }
    }
  }

  .informationMU {
    .infotitle {
      margin-bottom: 30px;

      h2 {
        font-size: 48px;
      }
    }

    .infoimage {
      img {
        width: 100%;
        margin: 0 auto;
        mix-blend-mode: exclusion;
        filter: brightness(1.3);
        max-width: 420px;

      }
    }

    .infotxt {
      h3 {
        font-size: 42px;
        margin-bottom: 20px;
      }

      p {
        letter-spacing: 2px;
        color: #c9c9c9;
        text-align: justify;

        span {
          color: #ffffff;
          font-weight: 100;
        }
      }
    }
  }

  .Toastify__progress-bar-theme--light {
    background: #ce86f9;
  }

  .Toastify__toast {
    color: #000000;
    font-size: 18px;
  }

  .bgVideoBody {
    -o-object-fit: cover;
    object-fit: cover;
    background-color: black;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    opacity: 0.0001;

    &,
    video {
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed to center vertically */
  }
  .loader {
    width: var(--loader-size);
    height: var(--loader-size);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    img{
      max-width: 200px;
    }
  }
  


  .countDownMu {
    text-align: center;
    margin: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 28px;
    padding-top: 25px;
    flex-wrap: wrap;

    b {
      display: flex;
      text-align: center;
      background-color: #ffffff;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      color: #000000;
      font-size: 20px;
      margin: 0px 10px;
      border-radius: 6px;
      position: relative;

      span {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        font-family: "Power Grotesk UltBd";
      }
    }

    p {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 15px auto 0px;
      text-align: center;
      font-size: 16px;
      font-family: "Power Grotesk UltBd";


    }
  }
}

.bannerbtn2 {
  display: flex;
  justify-content: center;
  align-items: center;
}


.color-changing-element {
  mix-blend-mode: exclusion;
  width: 100%;
  height: auto;
  max-width: 500px;
  margin: 0 auto 50px;

}
.info-group{
  p{
    line-height: 1.5 !important;
    font-size: 16px !important;
  }
}
.titlebox {
  text-align: center;
  max-width: 500px;
  margin: 0px auto 80px;
  position: relative;

  &::before,
  &::after {
      content: "";
      position: absolute;
      top: 60%;
      width: 100%;
      height: 70px;
      background-image: url(../../Assets//images//titleframe.svg);
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 9;
      filter: hue-rotate(40deg);
      animation: blinkers 1.5s linear infinite;
        filter: drop-shadow(0 0 2px #50DBFF) hue-rotate(40deg);
        transition: 0.9s;
  }
  @keyframes blinkers {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

  &::before {
      left: 100%;
      transform: translate(5%, -50%);
  }

  &::after {
      right: 100%;
      transform: translate(-5%, -50%) scaleX(-1);
  }

  &.titleboxNft {
      max-width: 600px;
      margin: 0px auto 10px;

      h2 {
          margin-bottom: 10px;
      }

      &::before,
      &::after {
          display: none;
      }
  }

  // h6 {
  //     color: var.$colour-theme;
  //     margin-bottom: 15px;
  //     font-size: 20px;
  // }

  h2 {
      font-size: 48px;
      margin-bottom: 25px;
      line-height: 1;
      text-shadow: 2px 2px 50px #50dbff;
  }
}

@media only screen and (max-width: 767px) {
  .titlebox {
    &::before,
    &::after {
   display: none  ;
    }
    
  }
}
