.cardbox2 {
  border-radius: 8px;
  border: 1px solid hsl(199.38, 71.03%, 56.51%);
}
.cardbox2 h2 {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: 600;
}
.cardbox2 p {
  margin: 0px;
  font-size: 16px;
}

.os_row2 {
  display: flex;
  flex-wrap: wrap;
}

.boxsmain2 {
  margin-top: 140px;
}
.boxsmain2 .os_container {
  max-width: 1320px;
}
.boxsmain2 .os_row {
  justify-content: center;
}
.boxsmain2 .col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
}

.cardbox2 {
  padding: 0px 0px;
  border-radius: 15px;
  position: relative;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  height: auto;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  /* background: transparent; */
}
.cardbox2 .vtn-card-img {
  opacity: 0.5;
  filter: hue-rotate(298deg);
  width: 100%;
}
.cardbox2 .iconbox {
  max-width: 100px;
  margin: 15px auto;
  position: relative;
  border-radius: 50%;
  padding: 0;
}
.cardbox2 .iconbox img {
  width: 100%;
  height: auto;
  animation: move 2s linear infinite;
}
.cardbox2 h2 {
  margin: 0 0 0px;
  font-size: 20px;
  font-weight: 600;
}
.cardbox2 p {
  margin: 0px;
  font-size: 16px;
}

@keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media only screen and (max-width: 768px) {
  .boxsmain2 .col4 {
    padding: 8px;
  }
  .boxsmain2 .cardbox2 {
    padding: 0px 1px;
  }
  .boxsmain2 .cardbox2 p {
    font-size: 16px;
    min-height: 35px;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
@media only screen and (max-width: 575px) {
  .boxsmain2 .col4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=Analytics.css.map */